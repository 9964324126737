/**
 * Reset partial styles
 */
@import './_variables';

// @see https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
*,
::after,
::before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

a,
button {
  color: inherit;
}

// @see http://unstoppablerobotninja.com/entry/fluid-images
audio,
embed,
iframe,
img,
input,
object,
table,
video {
  max-width: 100%;
}

// @see https://css-tricks.com/eliminate-jumps-in-horizontal-centering-by-forcing-a-scroll-bar
body {
  overflow-y: scroll;

  // Clearfix body
  &::after {
    clear: both;
    content: '';
    display: table;
  }
}

body,
html {
  height: 100%;
  position: relative;
  width: 100%;
}

button {
  cursor: pointer;
}

// @see http://www.paulirish.com/2012/box-sizing-border-box-ftw
html {
  box-sizing: border-box;
}

// Reset Normalize margins/padding
fieldset,
figure,
h1 {
  margin: 0;
}

fieldset {
  border: 0;
  padding: 0;
}

// @see https://github.com/ftlabs/fastclick/issues/60
label > * {
  pointer-events: none;
}

table {
  width: 100%;
}
