/**
 * This file is generated, don't change it manually.
 */
@font-face {
  font-family: icon-fonts;
  font-style: normal;
  font-weight: normal;
  src: url('/assets/icons/icon-fonts.woff2') format('woff2')
    , url('/assets/icons/icon-fonts.woff') format('woff')
    , url('/assets/icons/icon-fonts.ttf') format('truetype')
    , url('/assets/icons/icon-fonts.svg#icon-fonts') format('svg');
}

.icon-fonts {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-family: 'icon-fonts';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-rendering: auto;
  vertical-align: middle;
}

.icon-fonts-adult:before {
  content: '\EA01';
}

.icon-fonts-arobase:before {
  content: '\EA02';
}

.icon-fonts-best-price:before {
  content: '\EA03';
}

.icon-fonts-breakfirst-card:before {
  content: '\EA04';
}

.icon-fonts-breakfirst:before {
  content: '\EA05';
}

.icon-fonts-building:before {
  content: '\EA06';
}

.icon-fonts-calendar-engine:before {
  content: '\EA07';
}

.icon-fonts-calendar:before {
  content: '\EA08';
}

.icon-fonts-card:before {
  content: '\EA09';
}

.icon-fonts-close:before {
  content: '\EA0A';
}

.icon-fonts-enveloppe:before {
  content: '\EA0B';
}

.icon-fonts-facebook-big:before {
  content: '\EA0C';
}

.icon-fonts-facebook:before {
  content: '\EA0D';
}

.icon-fonts-geolocation:before {
  content: '\EA0E';
}

.icon-fonts-hotel-mark:before {
  content: '\EA0F';
}

.icon-fonts-instagram:before {
  content: '\EA10';
}

.icon-fonts-link:before {
  content: '\EA11';
}

.icon-fonts-list:before {
  content: '\EA12';
}

.icon-fonts-map:before {
  content: '\EA13';
}

.icon-fonts-marker-2:before {
  content: '\EA14';
}

.icon-fonts-marker:before {
  content: '\EA15';
}

.icon-fonts-phone:before {
  content: '\EA16';
}

.icon-fonts-play:before {
  content: '\EA17';
}

.icon-fonts-position:before {
  content: '\EA18';
}

.icon-fonts-profil:before {
  content: '\EA19';
}

.icon-fonts-reload:before {
  content: '\EA1A';
}

.icon-fonts-restaurant-menu:before {
  content: '\EA1B';
}

.icon-fonts-route:before {
  content: '\EA1C';
}

.icon-fonts-search-engine:before {
  content: '\EA1D';
}

.icon-fonts-search:before {
  content: '\EA1E';
}

.icon-fonts-select-arrow:before {
  content: '\EA1F';
}

.icon-fonts-suitcase:before {
  content: '\EA20';
}

.icon-fonts-triple-bed:before {
  content: '\EA21';
}

.icon-fonts-twin-bed:before {
  content: '\EA22';
}

.icon-fonts-youtube:before {
  content: '\EA23';
}
