/**
 * Main styles
 */
 @import (inline) 'npm://normalize.css/normalize.css';
 @import '../common.accor.com/styles-global';

  @import './partials/_miscellaneous';
  @import './partials/_mixins';
  @import './partials/_reset';
  @import './partials/_typography';
  @import './partials/_utilities';
  @import './partials/_variables';

 // Fix header height fot cls

 #ah-header-wrapper{
   z-index: 9003;
   display: block!important;

   @media only screen and (min-width: 768px) {
     height: 4.5rem;
   }

   @media only screen and (max-width:767px) {
     height: 3.8rem;
   }
 }
