/**
 * Utilities partial styles
 */
@import './_variables';

.u-hidden {
  &\@not-phone {
    @media screen and (min-width: (@breakpoint-phone + 1px)) {
      display: none !important;
    }
  }

  &\@phone {
    @media screen and (max-width: @breakpoint-phone) {
      display: none !important;
    }
  }
}

// Accessible hiding allows an element to disappear from the screen,  while being accessible to screen reader users.
.sr-only {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
