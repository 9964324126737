/**
 * Miscellaneous partial styles
 */
@import './_variables';

// @see https://github.com/aFarkas/lazysizes
.lazyload {
  &ed {
    max-width: inherit;
    opacity: 1;
    transition: opacity @transition-duration;
  }

  &ing {
    opacity: 0;
  }
}
