
/**
 * Main styles
 */

@import '../common.accor.com/styles';

@import './partials/_variables';

@import './assets/icons/_icon-fonts';

.font-face('Lato', '@{fonts-path}lato/bold/lato-bold', 700, normal, 'latobold');
.font-face('Lato', '@{fonts-path}lato/light/lato-light', 300, normal, 'latolight');
.font-face('Lato', '@{fonts-path}lato/regular/lato-regular', 400, normal, 'latoregular');

.font-face('Manifoldcf', '@{fonts-path}manifoldcf/bold/manifoldcf-bold', 700, normal, 'manifoldcf-bold');
.font-face('Manifoldcf', '@{fonts-path}manifoldcf/regular/manifoldcf-regular', 400, normal, 'manifoldcf-regular');
.font-face('Manifoldcf', '@{fonts-path}manifoldcf/light/manifoldcf-light', 300, normal, 'manifoldcf-light');
.font-face('Megafresh', '@{fonts-path}megafresh/regular/megafresh-regular', 400, normal, 'megafresh-regular');

html,
body {
  background: @white;
  height: auto;
  overflow-x: hidden;
  overflow-y: auto;
}

html {
  &.has-popin {
    overflow-y: hidden !important;
  }

  img {
    max-width: 100%;

    &.lazyloaded {
      max-width: 100%;
    }
  }
}

* {
  backface-visibility: hidden;
}

button {
  background: none;
  border: 0;
  cursor: pointer;
}

.button {
  background: none;

  &--bordered {
    border: 2px solid @lightgrey;
  }
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;

  &--inline {
    .list__item {
      display: inline-block;
    }
  }
}

.section {
  &.with-popin {
    .has-popin & {
      z-index: 10000;
    }
  }

  &--full-page {
    height: calc(100vh - @height-primaryHeader);
    width: 100%;

    @media @smartphone {
      height: calc(100vh - @height-primaryHeader-mobile);
    }
  }

  &__baseline {
    display: block;

    &__text {
      color: @darktext;
      display: block;
      font-size: 16 * @rem;
      font-weight: 700;
      margin: 0 auto;
      max-width: 90%;
      text-align: center;
    }
  }

  &__title {
    font-family: 'Manifoldcf';
    font-size: 50 * @rem;
    font-weight: 300;
    line-height: 1;
    margin: 0 auto;
    max-width: 90%;
    padding-bottom: 30px;
    text-align: center;

    @media @smartphone {
      font-size: 40 * @rem;
      padding-bottom: 20px;
    }

    &--brand-color {
      color: @yellow;
    }
  }
}

.top-banner {
  z-index: 5000;
}

@keyframes spinner {
  to {
    transform: rotate(360deg) translate3d(0, 0, 0);
  }
}

.loading:before {
  animation: spinner 1s linear infinite;
  border-radius: 50%;
  border-right: 2px solid transparent;
  border-top: 2px solid @yellow;
  box-sizing: border-box;
  content: '';
  height: 55px;
  left: 50%;
  margin-left: -12.5px;
  margin-top: -12.5px;
  position: absolute;
  top: 50%;
  width: 55px;
}

.hidden-on-load,
.hidden-item {
  display: none;
}

.breadcrumb {
  display: block;
  font-size: 12 * @rem;
  margin: 0 auto;
  max-width: 1280px;
  padding: 10px 40px;

  @media @smartphone {
    display: none;
  }

  &__item {
    color: @lightgrey;
    text-decoration: none;
  }

  a {
    &:hover {
      text-decoration: underline;
    }
  }
}

.festival-guide {
  background: @festival-guide-color;
}

.beach-break {
  background: @beach-break-color;
}

.food-trip {
  background: @food-trip-color;
}

.ride-style {
  background: @ride-style-color;
}

.road-book {
  background: @road-book-color;
}

.nature-stop {
  background: @nature-stop-color;
}

.scroll-downs {
  bottom: 34px;
  height: 27px;
  left: 50%;
  position: absolute;
  transform: translate(-50%, 0);
  width: 18px;
  z-index: 20;

  @media @smartphone {
    bottom: 80px;
  }

  &:after {
    animation-duration: 2.2s;
    animation-iteration-count: 2;
    animation-name: scroll;
    animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
    background-color: @white;
    border-radius: 25%;
    content: '';
    height: 5px;
    left: 50%;
    margin-left: -1px;
    position: absolute;
    top: 4px;
    width: 2px;
  }

  &:before {
    border: 2px solid @white;
    border-radius: 25px;
    content: '';
    display: block;
    height: 27px;
    left: 0;
    opacity: 0.75;
    position: absolute;
    top: 0;
    width: 18px;
  }

  &--grey {
    &:after {
      background-color: @lightgrey;
    }

    &:before {
      border-color: @lightgrey;
    }
  }
}

@keyframes scroll {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(10px);
  }
}

#counter {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  margin: 95px 0 auto;
  max-width: 100%;
  padding: 25px;
  position: absolute;
  white-space: nowrap;
  width: 800px;
  z-index: 999;

  @media @smartphone {
    margin: 55px 0 auto;
  }

  @media @tablet {
    margin: 55px 0 auto;
  }

  li {
    color: @white;
    flex: 1;
    font-family: 'Manifoldcf';
    font-size: 110 * @rem;
    font-weight: 300;
    text-shadow: 0 0 10px @black;
  }

  h3 {
    color: @white;
    font-family: 'Megafresh';
    font-size: 5.1rem;
    font-weight: 500;
    line-height: 1;
    text-align: left;

    @media @tablet {
      font-size: 3.7rem;
    }

    @media @smartphone {
      font-size: 3.5rem;
      line-height: 1.2;
    }

    @media @mobile {
      font-size: 3rem;
    }
  }

  span {
    &.percent {
      left: 2em;
      position: absolute;

      @media @tablet {
        left: 1.3em;
        top: 2px;
      }

      @media @smartphone {
        left: 1.5em;
        top: 10px;
      }

      &::after {
        display: inline-block;
      }
    }
  }
}

/* -- OVERRIDE ERM POPIN CSS AND HIDE MEMBER CARD FIELD AND FIX ERM DATEPICKER -- */

body .erm {
  position: relative;
  z-index: 10000;

  .erm__form__field--fidelityCard {
    display: none !important;
  }

  .erm__form__fieldset__content--right {
    visibility: hidden !important;
  }
}

body.erm__notScrollPage {
  #link-navigation.link-navigation {
    display: none;
    visibility: hidden;
  }

  .adunit[data-adunit='overTopHeader'].is-done {
    display: none;
    visibility: hidden;
  }
}

@media (min-width: 1024px) {
  body .erm .erm__popin {
    height: 100%;
    top: 0;
  }
}

.erm.for .erm__popin .erm__content .erm__form .erm__form__fieldset .erm__form__fieldset__content input.hasDatepicker,
#erm__form__customDatepickers #ui-datepicker-div.ui-datepicker .ui-datepicker-next,
#erm__form__customDatepickers #ui-datepicker-div.ui-datepicker .ui-datepicker-prev {
  z-index: 9;
}
